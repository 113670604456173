import { CircularProgress, CircularProgressProps } from '@mui/material';
import React from 'react';

const LoadingSpinner = ({
    isActive,
    size,
    color
}: {
    isActive: boolean;
    size?: CircularProgressProps['size'];
    color?: CircularProgressProps['color'];
}) =>
    isActive ? (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                top: 0,
                left: 0
            }}>
            <CircularProgress color={color} size={size} />
        </div>
    ) : null;

export default LoadingSpinner;
