import { GetServerSideProps, NextPage } from 'next';
import Router from 'next/router';
import Head from 'next/head';
import { useState } from 'react';
import { motion } from 'framer-motion';
import nextCookie from 'next-cookies';
import { PageAnimationVariants } from '../lib/utils/animation';
import { Input } from '../components/shared/inputs';
import LoadingSpinner from '../components/shared/loading-spinner';
import { Post } from '../lib/utils/utils';
import { GoogleLogin, GoogleCredentialResponse, GoogleOAuthProvider } from '@react-oauth/google';

type LoginResponse = {
    login: string;
    token: JsonWebKey;
};

const LoginPage: NextPage = () => {
    const [creds, setCreds] = useState({
        email: '',
        password: ''
    });

    const [isLoading, setIsLoading] = useState(false);

    const [errMsg, setErrMsg] = useState(null);

    const handleChange = (e) => {
        setCreds({ ...creds, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        setErrMsg(null);

        try {
            const result = await Post<LoginResponse>(`api/login/`, creds);

            if (result?.token) {
                Router.push('/');
            } else {
                throw new Error('Token not returned');
            }
        } catch (e) {
            setIsLoading(false);
            setErrMsg(e.message);
        }
    };

    const onGoogleSuccess = async (response: GoogleCredentialResponse) => {
        const result = await Post<LoginResponse>(`api/login/`, response);
        if (result?.token) {
            Router.push('/');
        } else {
            throw new Error('Token not returned');
        }
    };

    return (
        <>
            <Head>
                <title>Mrs Wordsmith</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            </Head>
            <motion.div
                initial="initial"
                animate="enter"
                exit="exit"
                variants={PageAnimationVariants}>
                <section className="hero is-success is-fullheight">
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <div className="column is-4 is-offset-4">
                                <LoadingSpinner isActive={isLoading} />
                                <div className="box">
                                    <figure className="avatar">
                                        <img src="/logo.png" />
                                    </figure>
                                    <form onSubmit={handleSubmit}>
                                        <div className="field">
                                            <div className="control">
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    defaultValue={creds.email}
                                                    placeholder="Your Email"
                                                    autoFocus={true}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <div className="control">
                                                <Input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    defaultValue={creds.password}
                                                    placeholder="Your Password"
                                                    autoFocus={true}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        {errMsg ? <div className="error-msg">{errMsg}</div> : ''}
                                        <button
                                            className="button is-block is-info is-medium is-fullwidth"
                                            type="submit">
                                            Login{' '}
                                            <i className="fa fa-sign-in" aria-hidden="true"></i>
                                        </button>
                                    </form>
                                    <br />
                                    OR
                                    <br />
                                    <br />
                                    <GoogleOAuthProvider clientId="345989947638-ji31f5muepu7k8qi8jndimp07v3t716i.apps.googleusercontent.com">
                                        <GoogleLogin
                                            ux_mode="popup"
                                            theme="filled_blue"
                                            onSuccess={onGoogleSuccess}
                                            onError={() => {
                                                console.warn('Login failed');
                                            }}
                                            useOneTap
                                            containerProps={{
                                                style: { display: 'flex', justifyContent: 'center' }
                                            }}
                                        />
                                    </GoogleOAuthProvider>
                                </div>
                                {/* <p className="has-text-grey">
                                <a href="/">Sign Up</a> &nbsp;·&nbsp;
                                <a href="/">Forgot Password</a> &nbsp;·&nbsp;
                                <a href="/">Need Help?</a>
                            </p> */}
                            </div>
                        </div>
                    </div>
                    <style jsx>{`
                        .error-msg {
                            border: 1px solid red;
                            border-radius: 5px;
                            color: #990000;
                            background: #ffe5e5;
                            padding: 10px;
                            margin-bottom: 20px;
                        }
                        .hero.is-success {
                            background: #f2f6fa;
                        }
                        .hero .nav,
                        .hero.is-success .nav {
                            -webkit-box-shadow: none;
                            box-shadow: none;
                        }
                        .box {
                            margin-top: 5rem;
                        }
                        .avatar {
                            margin-top: -70px;
                            padding-bottom: 20px;
                        }
                        .avatar img {
                            padding: 20px;
                            background: #fff;
                            max-width: 250px;
                            -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
                                0 0 0 1px rgba(10, 10, 10, 0.1);
                            box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
                                0 0 0 1px rgba(10, 10, 10, 0.1);
                        }
                        input {
                            font-weight: 300;
                        }
                        p {
                            font-weight: 700;
                        }
                        p.subtitle {
                            padding-top: 1rem;
                        }

                        .login-hr {
                            border-bottom: 1px solid black;
                        }

                        .has-text-black {
                            color: black;
                        }

                        .field {
                            padding-bottom: 10px;
                        }

                        .fa {
                            margin-left: 5px;
                        }
                    `}</style>
                </section>
            </motion.div>
        </>
    );
};

export const getServerSideProps: GetServerSideProps = async (pageContext) => {
    const token = nextCookie(pageContext)['access_token'];

    if (token) {
        const { isLoggedIn } = (await import('../lib/middleware/authentication')).default;
        try {
            await isLoggedIn(token);
            return { props: {}, redirect: { destination: '/' } };
        } catch {
            console.info('Not logged in');
        }
    }

    return {
        props: {}
    };
};

export default LoginPage;
