import {
    FunctionComponent,
    DetailedHTMLProps,
    InputHTMLAttributes,
    TextareaHTMLAttributes,
    SelectHTMLAttributes
} from 'react';
import * as React from 'react';

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type Input = FunctionComponent<InputProps>;
export const Input: Input = ({
    id,
    name,
    type,
    defaultValue,
    placeholder,
    onChange,
    autoFocus,
    onKeyPress,
    value
}: InputProps) => {
    return (
        <div className="field">
            <div className="control">
                <input
                    id={id}
                    name={name}
                    type={type}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    autoFocus={autoFocus ? true : false}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    value={value}
                    className="input"
                />
            </div>
        </div>
    );
};

type TextAreaProps = DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
>;
type TextArea = FunctionComponent<TextAreaProps>;
export const TextArea: TextArea = ({
    id,
    name,
    defaultValue,
    placeholder,
    onChange,
    autoFocus
}: TextAreaProps) => {
    return (
        <>
            <div className="field">
                <div className="control">
                    <textarea
                        id={id}
                        name={name}
                        className="textarea"
                        placeholder={placeholder}
                        defaultValue={defaultValue}
                        autoFocus={autoFocus ? true : false}
                        onChange={onChange}></textarea>
                </div>
            </div>
        </>
    );
};

type Options = {
    options: { id: string; value: string; label: string; name?: string; isSelected?: boolean }[];
};

type CheckboxProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type Checkbox = FunctionComponent<CheckboxProps>;
export const Checkbox: Checkbox = ({
    id,
    name,
    defaultValue,
    placeholder,
    onChange,
    autoFocus
}: CheckboxProps) => {
    const selectChange = (e) => {
        const value = e.target.checked;
        e.target.value = value;
        onChange(e);
    };
    return (
        <>
            <div className="field">
                <div className="control">
                    <label className="checkbox">
                        <input
                            id={id}
                            name={name}
                            type="checkbox"
                            onChange={selectChange}
                            autoFocus={autoFocus ? true : false}
                            defaultValue={defaultValue}
                        />
                        {placeholder}
                    </label>
                </div>
            </div>
        </>
    );
};

type SelectProps = DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> &
    Options;
type Select = FunctionComponent<SelectProps>;
export const Select: Select = ({
    id,
    name,
    defaultValue,
    placeholder,
    onChange,
    autoFocus,
    options
}: SelectProps) => {
    return (
        <>
            <div className="field">
                <div className="control">
                    <div className="select">
                        <select
                            id={id}
                            name={name}
                            defaultValue={defaultValue}
                            placeholder={placeholder}
                            onChange={onChange}
                            autoFocus={autoFocus ? true : false}>
                            {options.map((i) => {
                                return (
                                    <option id={i.id} key={i.id} value={i.value}>
                                        {i.label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>
        </>
    );
};

type RadioProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
    Options;
type Radio = FunctionComponent<RadioProps>;
export const Radio: Radio = ({ onChange, options }: RadioProps) => {
    return (
        <>
            <div className="field">
                <div className="control">
                    {options.map((i) => {
                        return (
                            <label key={i.value} className="radio">
                                <input
                                    key={i.value}
                                    defaultChecked={i.isSelected}
                                    name={i.name}
                                    type="radio"
                                    onChange={onChange}
                                    value={i.value}
                                />
                                {i.label}
                            </label>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
